html,
body {
    margin: 0;
    background-color: rgb(35, 31, 32);
    font-family: "Poppins";
}

textarea {
    width: 56rem;
    height: 15rem;
}

.info {
    margin-top: 24px;
}

.main {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 80%;
}

.container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.input {
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: rgb(35, 31, 32);
    font-size: 16px;
    resize: none;
    color: white;
    font-size: 32px;
    &:focus-visible {
        outline: none;
    }
}

header {
    width: 100%;
    display: flex;
    justify-content: center;
    button,
    a {
        padding: 4px 20px;
        font-size: 24px;
        background-color: transparent;

        border: solid 1px;
        border-top: none;
        border-color: white;
        color: white;

        text-decoration: none;

        &:not(:last-child) {
            margin-right: 1rem;
        }

        &:hover {
            cursor: pointer;
            border-color: #49eacb;
            color: #49eacb;
        }

        &:active {
        }
    }
}

.btn {
    padding: 12px 20px;
    font-size: 24px;

    background-color: transparent;

    border: solid 1px;
    border-color: rgb(112, 199, 186);
    color: rgb(112, 199, 186);

    &:hover {
        cursor: pointer;
        border-color: white;
        color: white;
    }

    &.secondary {
        color: white;
        border-color: white;
        &:hover {
            border-color: rgb(112, 199, 186);
            color: rgb(112, 199, 186);
        }
    }
}

.info {
    width: 100%;
    margin: auto;
    text-align: center;
    margin-top: 4rem;

    a {
        color: #70c7ba;
        font-size: 24px;
    }
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

#canvas-container {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.w-100 {
    width: 100%;
}

#Layer_2 {
    transform-origin: center;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotate;
    -moz-animation-duration: 10s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    animation-name: rotate;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.help {
    color: white;
    font-size: 14px;
    margin: 0;
}
