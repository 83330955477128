html, body {
  background-color: #231f20;
  margin: 0;
  font-family: Poppins;
}

textarea {
  width: 56rem;
  height: 15rem;
}

.info {
  margin-top: 24px;
}

.main {
  width: 100%;
  height: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.container {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 1rem;
  display: flex;
}

.input {
  box-sizing: border-box;
  resize: none;
  color: #fff;
  background-color: #231f20;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 32px;
}

.input:focus-visible {
  outline: none;
}

header {
  justify-content: center;
  width: 100%;
  display: flex;
}

header button, header a {
  color: #fff;
  background-color: #0000;
  border: 1px solid #fff;
  border-top: #fff;
  padding: 4px 20px;
  font-size: 24px;
  text-decoration: none;
}

header button:not(:last-child), header a:not(:last-child) {
  margin-right: 1rem;
}

header button:hover, header a:hover {
  cursor: pointer;
  color: #49eacb;
  border-color: #49eacb;
}

.btn {
  color: #70c7ba;
  background-color: #0000;
  border: 1px solid #70c7ba;
  padding: 12px 20px;
  font-size: 24px;
}

.btn:hover {
  cursor: pointer;
  color: #fff;
  border-color: #fff;
}

.btn.secondary {
  color: #fff;
  border-color: #fff;
}

.btn.secondary:hover {
  color: #70c7ba;
  border-color: #70c7ba;
}

.info {
  text-align: center;
  width: 100%;
  margin: 4rem auto auto;
}

.info a {
  color: #70c7ba;
  font-size: 24px;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.relative {
  position: relative;
}

.absolute {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

#canvas-container {
  z-index: 1;
  justify-content: center;
  display: flex;
  position: relative;
}

.w-100 {
  width: 100%;
}

#Layer_2 {
  transform-origin: center;
  animation-name: rotate;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.help {
  color: #fff;
  margin: 0;
  font-size: 14px;
}
/*# sourceMappingURL=index.4e4143ec.css.map */
